<template>
  <div class="consult-detail">
    <van-nav-bar fixed left-arrow placeholder @click-left="onClickLeft">
      <template #right>
        <div class="icon-right">
          <van-icon name="star" color="#E03E3F" @click="collectClick" v-if="collect" />
          <van-icon name="star-o" @click="collectClick" v-else />
          <van-icon name="share-o"  @click="share = true"/>
        </div>
      </template>
    </van-nav-bar>
     <div class="mcover" v-if="share" @click="share = false">
            <img :src="require('../../assets/images/share.png')">
          </div>
    <div class="consult-box">
      <div class="consult-top">
        <div class="consult-title">{{consult.title}}</div>
        <div class="consult-msg">
          <div class="consult-source" v-if="consult.source">{{consult.source}}</div>
          <div class="consult-time">{{ consult.createTime != null?getTimes.timestampToTime(consult.createTime):"" }}</div>
        </div>
      </div>
      <img :src="consult.pics">
      <div class="consult-content" v-html="$xss(consult.content)"></div>
    </div>
    <div class="annotation" v-html="$xss(annotation)"></div>
    <div class="building-box" v-if="house != null">
      <div class="building-title">楼盘信息</div>
      <div class="building-msg">
        <building-item :item="house" :id="house.id" />
      </div>
    </div>

    <div class="recommend-box" v-if="recommendList && recommendList.length > 0">
      <div class="item-title">
        <div class="title-left">推荐文章</div>

        <div class="recommend-item" v-for="item in recommendList" :key="item.id" @click="goHouseInformationDetail(item.id)">
          <div class="recommend-msg">
            <div class="recommend-name">{{item.title}}
            </div>
            <div class="recommend-source">{{item.source}}</div>
            <div class="recommend-time">{{ item.createTime != null?getTimes.timestampToTime(item.createTime):"" }}</div>
          </div>
          <div class="recommend-img">
            <img :src="item.pics ? item.pics : require('../../assets/images/placeholder.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="comment">
      <div class="comment-box" v-if="commentList && commentList.length > 0">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">
          <div class="comment-item" v-for="(item,index) in commentList" :key="item.id">
            <div class="comment-img">
              <img :src="item.userAvatar ? item.userAvatar : require('../../assets/images/comment.png')" alt="">
            </div>
            <div class="comment-msg">
              <div class="comment-name">{{item.userName}}</div>
              <div class="comment-content">{{item.content}}</div>
              <div class="comment-bottom">
                <div class="comment-time">{{ item.createTime != null?getTimes.timestampToTime(item.createTime):"" }}</div>
                <div class="comment-btn">
                  <div class="praise-btn" @click="thumbsUp(item.id,item.check,index)">
                    <van-icon name="like-o" v-if="!item.check" />
                    <van-icon color="#E03E3F" name="like" v-else /> 赞 ({{item.likeCount}})
                  </div>
                  <div class="delete-btn" v-if="item.delBtn" @click="delContent(item.id)">删除</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <!-- <div class="look-more">
          <span v-if="!more" @click="lookMore">点击查看更多~</span>
          <span v-else>没有更多了~</span>
        </div> -->
      </div>
      <div v-else>
        <van-empty image="error" description="暂无更多评论" />
      </div>
    </div>
    <div class="detail-bottom">
      <van-field v-model="comment" center ref="comment" @focus="activeFocus" @blur="blurFocus" maxlength="500">
        <template #button>
         <van-button size="small" v-if="commentFocus" @click="submitContent">提交</van-button>
        <div class="comment-num" v-else >
          <van-icon name="comment" color="#9A9A9A" size="18px" />
          <span>({{commentList.length}})</span>
        </div>
        </template>
      </van-field>

     
    </div>
  </div>
</template>
<script>
import buildingItem from "../../components/BuildingItem.vue";

export default {
  name: "consultDetail",
  data() {
    return {
      collect: false,
      consult: {},
      house: {},
      recommendList: [],
      commentList: [
      ],
      commentFocus: false,
      comment: "",
      more: false,
      id: "",
      annotation: "",
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 5,
      share:false
    };
  },
  created() {
    // 获取传递过来的资讯id
    this.id = this.$route.query.id;
  },
  methods: {
    onLoad() {
      this.loading = true;
      this.pageNum++;
     this.getHouseInformationDetail();
    },
    onClickLeft() {
      this.$router.push("/");
    },
    collectClick() {
      if (this.isLogin()) {
        const data = {
           //资讯id
          id: this.consult.id,
          //是否收藏
          collect: this.collect,
        };
        this.$post("information/collection", data).then((res) => {
          if (res.status == 200) {
            this.collect = !this.collect;
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    activeFocus() {
      this.commentFocus = true;
    },
    blurFocus() {
      if (this.comment == "") {
        this.commentFocus = false;
      }
      
    },
    lookMore() {
      this.more = true;
    },
    //点赞/取消点赞
    thumbsUp(userActionId, check, index) {
      // this.commentList[index].check = !this.commentList[index].check;
      if (this.isLogin()) {
        const data = {
          //评论id
          id: userActionId,
          //是否点赞
          check: check,
        };
        this.$post("information/commentLike", data).then((res) => {
          if (res.status == 200) {
            this.commentList[index].check = !check;
            if (check) {
              this.commentList[index].likeCount -= 1;
            } else {
              this.commentList[index].likeCount += 1;
            }
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    //添加评论
    submitContent() {
      this.$refs.comment.blur()
      if (this.isLogin()) {
        const data = {
          //资讯id
          id: this.consult.id,
          comment: this.comment,
        };
        if(this.comment.length == 0){
          return false;
        }
        this.$post("information/submitContent", data).then((res) => {
          if (res.status == 200) {
            this.commentList = [];
            this.pageNum = 1;
            this.comment = ""
            this.$toast.success(res.msg);
            this.goHouseInformationDetail(this.consult.id);
            document.body.scrollTop = document.documentElement.scrollTop = document.querySelector(".comment").offsetTop - 300

          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    delContent(id) {
      if (this.isLogin()) {
        const data = {
          //资讯下评论id
          id: id,
        };
        this.$post("information/delContent", data).then((res) => {
          if (res.status == 200) {
            this.commentList = [];
            this.pageNum = 1;
            this.$toast.success(res.msg);
            this.goHouseInformationDetail(this.consult.id);
            document.body.scrollTop = document.documentElement.scrollTop = document.querySelector(".comment").offsetTop - 300
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    //跳转到资讯详情
    goHouseInformationDetail(houseInformationId) {
      this.id = houseInformationId;
      this.commentList = []
      this.pageNum = 1
      this.getHouseInformationDetail();
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    getHouseInformationDetail() {
      const data = {
        //楼盘资讯id
        id: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$post("information/getHouseInformationDetail", data).then((res) => {
        if (res.status == 200) {
          this.finished = false;
            this.loading = false;
            this.commentList = this.commentList.concat(res.data.userActions);
            let flag = false
            //防止出现每页楼盘长度等于查询的楼盘长度
            if(res.data.userActions.length == res.pageSize  && 
             res.data.count % this.pageSize == this.pageNum && 
             res.data.count / this.pageSize == 0){
              flag = true;
            }
            if(this.commentList.length >= res.data.count || (res.data.userActions.length < this.pageSize || flag)){
              this.finished = true
            }
          this.consult = res.data.houseInformation;
          console.log(this.consult)
          this.annotation = res.data.exemption;
          this.house = res.data.house;
          this.recommendList = res.data.houseInformations;
          // this.commentList = res.data.userActions;
          this.collect = res.data.collect
            this.$getShareInfo(this.consult.title,this.BASE.API_URL + '/images/logo.png');
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  components: {
    buildingItem,
  },
  mounted() {
    this.getHouseInformationDetail();
  },
};
</script>
<style lang="less" scoped>
.consult-detail {
  padding-bottom: 50px;
  /deep/img{
    max-width: 100% !important;
    width: auto;
    height: auto;
  }
  /deep/.van-nav-bar {
    z-index: 999;
    border-bottom: 1px solid #ddd;
    .van-icon {
      color: #9f9f9f;
      font-size: 18px;
    }
    .icon-right {
      display: flex;
      .van-icon {
        margin-left: 15px;
        font-size: 24px;
        color: #a3a3a3;
      }
    }
  }
  .consult-box {
    background-color: #fff;
    padding: 14px;
    .consult-top {
      border-bottom: 1px solid #ddd;
      .consult-title {
        color: #333;
        font-size: 18px;
        line-height: 1.5;
      }
      .consult-msg {
        color: #858585;
        font-size: 14px;
        line-height: 2;
        // display: flex;
        align-items: center;
        .consult-source {
          margin-right: 10px;
        }
      }
    }
  }

  .annotation {
    padding: 14px;
    font-size: 14px;
    color:#AAAAAA;
  }
  .building-box {
    background-color: #fff;
    padding: 0 14px 14px;
    margin-bottom: 15px;
    .building-title {
      color: #333;
      font-size: 16px;
      line-height: 45px;
    }
  }
  .recommend-box {
    padding: 14px;
    background-color: #fff;
    margin-bottom: 15px;
    .item-title {
      line-height: 30px;
      .title-left {
        color: #666;
        font-size: 15px;
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 14px;
          border-radius: 2px;
          background-color: #09d5c6;
        }
      }
    }
    .recommend-item {
      display: flex;
      border-bottom: 1px solid #eee;
      padding: 12px 0;
      &:last-child {
        border-bottom: 0;
      }
      .recommend-msg {
        flex: 1;
        height: 86px;
        width: 0;
        .recommend-name {
          word-break: break-word;
          text-overflow: ellipsis;
          display: -webkit-box;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          -webkit-line-clamp: 2;
          overflow: hidden;
          color: #333;
          font-size: 16px;
          line-height: 23px;
          height: 46px;
        }
        .recommend-source {
          color: #858585;
          font-size: 13px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .recommend-time {
          color: #858585;
          font-size: 13px;
          line-height: 20px;
        }
      }
      .recommend-img {
        width: 130px;
        height: 86px;
        margin-left: 10px;
        img {
          width: 130px;
          height: 86px;
        }
      }
    }
  }
  .detail-bottom {
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    background-color: #fff;
    display: flex;
    border-top: 1px solid #ddd;
    /deep/.van-cell {
      padding: 0;
      .van-field__body {
        input {
          background-color: #f1f1f1;
          border-radius: 15px;
          height: 30px;
          padding: 0 15px;
        }
      }
      .van-field__button {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        .comment-num {
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          font-size: 15px;
          color: #9a9a9a;
          .van-icon {
            margin-right: 5px;
          }
        }
        .van-button {
          border: 0;
          font-size: 14px;
          padding: 0;
          color: #9a9a9a;
          &::before {
            background-color: #fff;
          }
        }
      }
    }
  }
  .comment {
    background-color: #fff;
    padding: 14px 14px 0;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    .comment-box {
      .comment-item {
        display: flex;
        margin-top: 10px;
        // &:last-child{
        //     .comment-bottom{
        //         border-bottom: 0 !important;
        //     }
        // }
        .comment-img {
          width: 45px;
          height: 45px;
          flex-shrink: 0;
          margin-right: 10px;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }
        .comment-msg {
          flex: 1;
          .comment-name {
            color: #3b79ad;
            font-size: 16px;
            line-height: 28px;
          }
          .comment-content {
            color: #333;
            font-size: 15px;
            line-height: 24px;
          }
          .comment-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;
            .comment-time {
              color: #777;
              font-size: 13px;
            }
            .comment-btn {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #777;
              .praise-btn {
                margin-right: 15px;
              }
            }
          }
        }
      }
      .look-more {
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #777;
        font-size: 14px;
      }
    }
    /deep/.van-empty {
      padding: 20px 0;
      .van-empty__image {
        width: 100px;
        height: 100px;
      }
    }
  }
  .consult-content{
    font-size: 13px;
    overflow: hidden;
  }
}
</style>